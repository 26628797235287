import { useState } from "react";
import s from "styled-components";
import { useOnChangeEffect } from "~/modules/utils/hooks";

const QUOTES = [
  {
    text: "I was able to see potential employers look me up, and I got my first job after launching my website!",
    name: "Jennifer G, Georgia",
  },
  {
    text: "Now I can organize and show all of my writing and published works in one place, online.",
    name: "Matthew S., California",
  },
  {
    text: "I can't believe how easy it is to use. I just copied my CV and added links, and now I have a full website.",
    name: "Janelle T., Illinois",
  },
  {
    text: "My old website took me a week to make. Publit took me less than an hour-and the website feels so fast.",
    name: "Cliff H., New York",
  },
  {
    text: "I saved about $100 this year switching to Publit, and it's so much easier to use.",
    name: "Jesse V., Pennsylvania",
  },
];

export default function Quotes() {
  const [index, setIndex] = useState(0);

  const jump = (i: number) => () => setIndex(i);

  useOnChangeEffect(index, () => {
    const timeout = setTimeout(() => {
      setIndex((i) => (i === QUOTES.length - 1 ? 0 : i + 1));
    }, 8000);

    return () => clearTimeout(timeout);
  });

  return (
    <Section>
      <Portal>
        <Slider
          style={{
            transform: `translateX(calc(-${index * 100}% - ${0 * 100}px))`,
          }}
        >
          {QUOTES.map((q, i) => (
            <Quote key={`quote-${i}`} active={index === i}>
              <Text>&ldquo;{q.text}&rdquo;</Text>
              <Name>{q.name}</Name>
            </Quote>
          ))}
        </Slider>
      </Portal>
      <SliderNavigation>
        {QUOTES.map((_, i) => (
          <SliderStop
            key={`quote-slider-stop-${i}`}
            active={index === i}
            onClick={jump(i)}
          />
        ))}
      </SliderNavigation>
    </Section>
  );
}

const Section = s.section`
padding: 60px 0;
background: radial-gradient(75.21% 77.36% at 100% 0%, rgba(0, 132, 255, 0.3) 0%, rgba(0, 132, 255, 0.206636) 54.53%, rgba(0, 132, 255, 0) 100%), radial-gradient(74.08% 76.2% at 0% 0%, #00C2FF 0%, #133364 100%);
overflow: hidden;
`;

const Portal = s.div`
width: 680px;
height: 300px;
margin: 160px auto 120px;

@media (max-width: 680px) {
  height: auto;
  width: 100vw;
}

@media (max-width: 550px) {
  margin: 0;
  margin-bottom: 60px;
}
`;

const Slider = s.div`
display: flex;

transition: transform 0.25s ease-in-out;
`;

const Quote = s.div<{ active: boolean }>`
flex-shrink: 0;

display: flex;
flex-direction: column;
align-items: center;
max-width: 680px;
box-sizing: border-box;
padding: 0 50px;

opacity: ${(props) => (props.active ? 1 : 0)};
color: white;
transition: opacity 0.25s ease-in-out;

@media (max-width: 680px) {
  margin: 0;
  width: 100vw;
}
`;

const Text = s.p`
margin: 0;

${(props) => props.theme.text.build("crimson", "xxxl")};

@media (max-width: 550px) {
  ${(props) => props.theme.text.build("crimson", "xxl")};
}
`;

const Name = s.p`
margin: 0;
margin-top: 10px;
align-self: flex-end;

${(props) => props.theme.text.build("crimson", "xxl")};

@media (max-width: 550px) {
  ${(props) => props.theme.text.build("crimson", "xl")};
}
`;

const SliderNavigation = s.div`
display: grid;
justify-content: center;
grid-template-columns: repeat(5, 40px);
grid-template-rows: 6px;
grid-gap: 10px;
`;

const SliderStop = s.button<{ active: boolean }>`
display: block;
margin: 0;

background: ${(props) => (props.active ? "white" : "rgb(255 255 255 / 30%)")};
border: none;
border-radius: 3px;
`;
