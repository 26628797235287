import Marketing from "components/marketing";
import Home from "components/marketing/home";
import HomeHero from "components/marketing/home/hero";

export default function HomePage() {
  return (
    <Marketing
      title="Home"
      description="Personal websites designed for professionals, students, writers, academics, and journalists"
    >
      <HomeHero />
      <Home />
    </Marketing>
  );
}
