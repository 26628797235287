import s from "styled-components";

import { Section } from "../shared";
import Domain from "./domain";
import FeaturesGrid from "./features";
import Quotes from "./quotes";
import Showcase from "./showcase";
import Sites from "./sites";

export default function MarketingHome() {
  return (
    <>
      <Showcase />
      <Quotes />
      <FeaturesGrid />
      <Domain />
      <Sites />
      <Section
        title="Publit is used by professionals and graduates everywhere"
        titleColor="var(--color-navy)"
        description="Graduates from elite liberal arts colleges, top institutions, and nationally competitive fellowships trust Publit to showcase all of their best experiences and professional work."
        descriptionMaxWidth="640px"
        background="linear-gradient(180deg, #E1F8FF 0%, rgba(234, 250, 255, 0) 100%)"
      >
        <UsedBy>
          <Schools>
            <School>
              <Logo src="/static/home/schools/usc-logo.png" />
              <Name>
                University of
                <br />
                Southern California
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/wharton-logo.png" />
              <Name>
                The Wharton
                <br />
                School
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/stanford-logo.png" />
              <Name>
                Stanford
                <br />
                University
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/dartmouth-logo.png" />
              <Name>
                Dartmouth
                <br />
                University
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/harvard-logo.png" />
              <Name>
                Harvard
                <br />
                University
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/yale-logo.png" />
              <Name>
                Yale
                <br />
                University
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/swarthmore-logo.png" />
              <Name>
                Swarthmore
                <br />
                College
              </Name>
            </School>
            <School>
              <Logo src="/static/home/schools/brown-logo.png" />
              <Name>
                Brown
                <br />
                University
              </Name>
            </School>
          </Schools>
        </UsedBy>
      </Section>
    </>
  );
}

const UsedBy = s.section`
${(props) => props.theme.css.contentWidth};
`;

const Schools = s.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
// grid-column-gap: 10px;
align-items: center;
`;

const School = s.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
margin: 30px 0;
height: 140px;

text-align: center;
color: black;
${(props) => props.theme.text.build("crimson", "medium")};
`;

const Logo = s.img`
display: block;
height: 80px;

${School}:nth-child(4) & {
  height: 70px;
}

${School}:nth-child(7) & {
  height: 100px;
}
`;

const Name = s.p`
margin: 0;
`;
