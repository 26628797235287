import s from "styled-components";
import { Section } from "../shared";

interface FeatureItem {
  category: string;
  title: string;
  description: string;
  graphic?: string;
}

const DATA: FeatureItem[] = [
  {
    category: "Content Editing",
    title: "Incredibly simple editing system",
    description:
      "It's as easy as clicking a few buttons and filling out a form.",
    graphic: "content-editing",
  },
  {
    category: "Cost",
    title: "Less than the cost of a cup of coffee",
    description: "$6.50/mo for professionals and $4.88/mo for undergraduates.",
    graphic: "cost",
  },
  {
    category: "Domain & URL",
    title: "We get your website address for you",
    description:
      "We search for the URL you want or transfer one you already own.",
    graphic: "search",
  },
  {
    category: "Convenience",
    title: "No design or coding experience is needed",
    description: "We have the templates ready for you to select and go live.",
    graphic: "coding",
  },
  {
    category: "Content Editing",
    title: "Built-in, easy blogging system included",
    description:
      "Publit was made for you to share your work and publish ideas.",
    graphic: "keyboard",
  },
  {
    category: "Personalization",
    title: "Pick the right template to showcase your history",
    description:
      "Select from our templates and switch between them seamlessly.",
    graphic: "templates",
  },
  {
    category: "Analytics",
    title: "See how often people visit your new website",
    description:
      "Our Iris Analytics system allows you to track who visits your site.",
    graphic: "analytics",
  },
  {
    category: "Search",
    title: "Become highly visible in search results",
    description:
      "Employers, recruiters, and future business associates will find you.",
    graphic: "search-results",
  },
  {
    category: "Personalization",
    title: "Personalize it with hundreds colors",
    description: "Select from hundreds of colors and click to swap templates.",
    graphic: "colors",
  },
];

export default function Features() {
  return (
    <Section
      title="Everything you need in a website builder"
      titleColor="var(--color-navy)"
      background="radial-gradient(45.95% 55.38% at 100% 0%, rgba(97, 0, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), radial-gradient(86.4% 52.63% at 50% 0%, rgba(0, 132, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF"
    >
      <Grid>
        {DATA.map((feature, i) => (
          <Feature key={`feature-${i}`}>
            <Category>{feature.category}</Category>
            <Title>{feature.title}</Title>
            <Description>{feature.description}</Description>
            <Graphic>
              <Image src={`/static/home/features/${feature.graphic}.svg`} />
            </Graphic>
          </Feature>
        ))}
      </Grid>
    </Section>
  );
}

const Grid = s.div`
display: grid;
grid-template-columns: repeat(3, 265px);
grid-template-rows: repeat(3, 265px);
grid-gap: 30px;
width: 840px;
margin: 0 auto;
margin-top: 60px;

@media (max-width: 900px) {
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(2, 265px);
  grid-template-rows: repeat(4, 265px);
  grid-gap: 20px;
}

@media (max-width: 580px) {
  grid-template-columns: 265px;
  margin-top: 40px;
}
`;

const Feature = s.div`
padding: 20px;

background: white;
border-radius: 20px;
border: 0.5px solid #D6D6D6;
box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
transition: 0.15s box-shadow ease-in-out;

${(props) => props.theme.shadows.party("hover")};

@media (max-width: 900px) {
  &:nth-child(9) {
    display: none;
  }
}

@media (max-width: 580px) {
  &:nth-child(n+5) {
    display: none;
  }
}
`;

const Category = s.p`
margin: 0;

color: #6E6E73;
${(props) => props.theme.text.build("manrope", "xs", "bold", ["capped"])};

${Feature}:hover & {
  color: var(--color-light-blue);
}
`;

const Title = s.h2`
margin: 5px 0 10px;

color: #133364;
${(props) => props.theme.text.build("manrope", "large", "bold")};
`;

const Description = s.p`
margin: 0;

color: black;
${(props) => props.theme.text.build("nunito", "s")};
`;

const Graphic = s.div`
margin-top: 6px;
`;

const Image = s.img``;
