import { useRouter } from "next/router";

import MarketingForm, { type FormSubmissionHandler } from "../form";

export default function Domain() {
  const router = useRouter();

  const onSubmit: FormSubmissionHandler = async (domain) => {
    if (domain.trim().length > 0) {
      router.push(`/welcome/early-domain?desiredURL=${domain}`);
      return { type: "success", message: "Redirecting..." };
    } else {
      return {
        type: "error",
        message: "Please try again.",
      };
    }
  };

  return (
    <MarketingForm
      title="Reserve your website address"
      titleColor="var(--color-light-blue)"
      description="Thousands of URLs and domains are purchased daily. Make sure you claim and purchase your website address before it's taken."
      descriptionColor="white"
      descriptionMaxWidth="500px"
      background="var(--color-navy)"
      inputType="text"
      inputPlaceholder="Add your desired URL here"
      helpText="Upon subscribing, our team will see if your desired website address is available. If not, we will search for alternatives."
      helpTextColor="white"
      onSubmit={onSubmit}
    />
  );
}
